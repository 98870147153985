@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

:root {
  --primary: #ff151f;
  --secondary: #e9e9e9;
}

html,
body,
#root,
.app,
.content {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

.app {
  display: flex;
  position: relative;
  height: 100%;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Topbar {
  padding: 0 31px 0 0;
  height: 70px;
}

.TopbarSearchInput {
  border-bottom: 1px solid #ababab;
}

.TopbarSearchInputText {
  color: var(--secondary);
  font-size: 16px !important;

  font-weight: 400 !important;
  word-wrap: break-word !important;
}

.css-9b6wkn-MuiInputBase-root {
  margin-left: 0px !important;
}

.TopbarProfilePhotoDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1px 1px 1px 15px;
  gap: 16px;
  height: 52px;
}

.TopbarLanguageDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  border-radius: 33px;
  height: 52px;
  width: 76px;
}

.TopbarProfileNameDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.TopbarProfileName {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 600;
  word-wrap: break-word;
}

.TopbarProfileRole {
  color: #5f5f5f;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
}

.TopbarProfilePhoto {
  border-radius: 50%;
  width: 53px;
  height: 53px;
}
.langBtnText {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;
}
.ProfileDropDownProfile {
  color: #25282b !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  line-height: 27px !important;
  word-wrap: break-word !important;
}
.LogoutDropDownProfile {
  color: #ff151f !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  line-height: 27px !important;
  word-wrap: break-word !important;
}
